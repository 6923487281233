// App.js
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Column from './column';
import { apiClient } from '../../service/api';
import { Content } from 'antd/es/layout/layout';
import { Button, Modal, Row } from 'antd';
import NewAvailability from '../../view/availability/insert-availability';
import './main.css'
import SimpleBackdrop from '../loading/loading';
import ResponsiveDialog from '../dialog/dialog';
import DialogConfirm from '../dialog-confirm/dialog-confirm';
import { AppBar, Autocomplete, Grid, Paper, TextField, Toolbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import customerService from '../../service/serviceCustomer';
import userService from '../../service/serviceUser';


const MainKamban = () => {

  const [columns, setColumns] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [openDialog, setOpenDialog] = useState();
  const [messageDialog, setMessageDialog] = useState();
  const [titleDialog, setTitleDialog] = useState();
  const [statusDialog, setStatusDialog] = useState();

  const [openDialogYesOrNo, setOpenDialogYesOrNo] = useState();
  const [messageDialogYesOrNo, setMessageDialogYesOrNo] = useState();
  const [titleDialogYesOrNo, setTitleDialogYesOrNo] = useState();
  const [statusDialogYesOrNo, setStatusDialogYesOrNo] = useState();

  const [openDialogReloadYesOrNo, setOpenDialogReloadYesOrNo] = useState();
  const [messageDialogReloadYesOrNo, setMessageDialogReloadYesOrNo] = useState();
  const [titleDialogReloadYesOrNo, setTitleDialogReloadYesOrNo] = useState();
  const [statusDialogReloadYesOrNo, setStatusDialogReloadYesOrNo] = useState();
  
  const [listCustomer, setListCustomer] = useState([]);
  const [listSaller, setListSaller] = useState([]);
  const [idSaller, setIdSaller] = useState([]);


  const [column, setColumn] = useState();
  const [task, setTask] = useState();


  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await apiClient.get('/tasks')
      setColumns(response.data); // Armazena as colunas no estado
    } catch (error) {
      console.error('Erro ao buscar dados do backend:', error);
    } finally {
      setIsLoading(false);
    }

  };

  // Função para buscar os dados do backend
  useEffect(() => {

    fetchData();

    var listCust = [];
        customerService.listCustomerActive().then(item => { 
            
            item.forEach(element => {
                listCust.push(element);
            });

            setListCustomer(listCust)
            
        }).catch(error => {
            console.log(error)
        });

    var listSaller = [];
        userService.listarUser().then(item => {

            item.forEach(element => {
              listSaller.push(element);
            });

            setListSaller(listSaller)
            
        }).catch(error => {
            console.log(error)
        });

  }, []);

  // Função para mover uma tarefa de uma coluna para outra
  const moveTask = (taskId, sourceColumnId, targetColumnId) => {
    setColumns((prevColumns) => {
      const sourceColumn = prevColumns.find(column => column.idcolumn === sourceColumnId);
      const targetColumn = prevColumns.find(column => column.idcolumn === targetColumnId);
  
      if (!sourceColumn || !targetColumn) {
        console.error("Colunas de origem ou destino não encontradas.");
        return prevColumns;
      }
  
      // Caso seja a mesma coluna, apenas reorganize as tarefas
      if (sourceColumnId === targetColumnId) {
        const reorderedTasks = [...sourceColumn.tasks];
        const taskIndex = reorderedTasks.findIndex(task => task.idbudget === taskId);
        if (taskIndex === -1) {
          console.error("Tarefa não encontrada na coluna de origem.");
          return prevColumns;
        }
  
        // Mover a tarefa dentro da mesma coluna
        const [movedTask] = reorderedTasks.splice(taskIndex, 1);
        reorderedTasks.push(movedTask); // Você pode ajustar essa lógica para a posição desejada
  
        const updatedColumn = {
          ...sourceColumn,
          tasks: reorderedTasks,
        };
  
        return prevColumns.map(column =>
          column.idcolumn === sourceColumnId ? updatedColumn : column
        );
      }
  
      // Caso as colunas sejam diferentes, mover entre elas
      const taskToMove = sourceColumn.tasks.find(task => task.idbudget === taskId);
      if (!taskToMove) {
        console.error("Tarefa não encontrada na coluna de origem.");
        return prevColumns;
      }
  
      const updatedSourceColumn = {
        ...sourceColumn,
        tasks: sourceColumn.tasks.filter(task => task.idbudget !== taskId),
        vltotalColumn: parseFloat(sourceColumn.vltotalColumn) - parseFloat(taskToMove.ticket || 0),
      };
  
      const updatedTargetColumn = {
        ...targetColumn,
        tasks: [...targetColumn.tasks, taskToMove],
        vltotalColumn: parseFloat(targetColumn.vltotalColumn || 0) + parseFloat(taskToMove.ticket || 0),
      };
  
      const newColumns = prevColumns.map(column => {
        if (column.idcolumn === sourceColumnId) return updatedSourceColumn;
        if (column.idcolumn === targetColumnId) return updatedTargetColumn;
        return column;
      });
  
      // Atualizar o backend apenas para mudanças de coluna
      apiClient.put('/update-task-position', { taskId, targetColumnId }).catch(error => {
        console.error("Erro ao atualizar posição no backend:", error);
      });
  
      return newColumns;
    });
  };
  


  const deleteTask = async (taskId, columnId) => {

    try {
      // Chamada para deletar a task no backend
      const response = await apiClient.delete(`/budget/${taskId}`);

      if (response.status === 204) {
        setColumns(prevColumns =>
          prevColumns.map(column => {
            if (column.idcolumn === columnId) {
              // Encontrar a task e subtrair o valor dela de vltotalColumn
              const taskToDelete = column.tasks.find(task => task.idbudget === taskId);
              const taskValue = taskToDelete ? parseFloat(taskToDelete.ticket) : 0;

              return {
                ...column,
                tasks: column.tasks.filter(task => task.idbudget !== taskId),
                vltotalColumn: parseFloat(column.vltotalColumn) - parseFloat(taskValue), // Subtrair o valor da task deletada
              };
            }
            return column;
          })
        );

        setOpenDialog(true);
        setStatusDialog('success')
        setTitleDialog("Muito bem!")
        setMessageDialog("Orçamento excluido com sucesso")
        console.log("Task deletada com sucesso.");

      } else {
        console.error("Erro ao deletar task:", response);
      }
    } catch (error) {
      console.error("Erro ao deletar task:", error);
    }
  };


  const reloadTask = async (taskId, columnId) => {

    try {
      // Chamada para deletar a task no backend
      const response = await apiClient.get(`/tasks`);

      if (response.status === 204) {
        setColumns(prevColumns =>
          prevColumns.map(column => {
            if (column.idcolumn === columnId) {
              // Encontrar a task e subtrair o valor dela de vltotalColumn
              const taskToDelete = column.tasks.find(task => task.idbudget === taskId);
              const taskValue = taskToDelete ? parseFloat(taskToDelete.ticket) : 0;

              return {
                ...column,
                tasks: column.tasks.filter(task => task.idbudget !== taskId),
                vltotalColumn: parseFloat(column.vltotalColumn) + parseFloat(taskValue), // Subtrair o valor da task deletada
              };
            }
            return column;
          })
        );

        setOpenDialog(true);
        setStatusDialog('success')
        setTitleDialog("Muito bem!")
        setMessageDialog("Orçamento excluido com sucesso")
        console.log("Task deletada com sucesso.");

      } else {
        console.error("Erro ao deletar task:", response);
      }
    } catch (error) {
      console.error("Erro ao deletar task:", error);
    }
  };

  const openHandleOk = () => {
    setIsModalVisible(true); // Fecha o modal ao clicar em "OK"
  };

  const handleOk = () => {
    setIsModalVisible(false); // Fecha o modal ao clicar em "OK"
  };

  const handleCancel = () => {
    setIsModalVisible(false); // Fecha o modal ao clicar em "Cancelar"
  };

  const handleClose = () => {
    setIsLoading(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const deleteTaskIntern = (taskId, columnId) => {
    setTask(taskId)
    setColumn(columnId)

    setStatusDialogYesOrNo('error');
    setTitleDialogYesOrNo("Excluir Orçamento ");
    setMessageDialogYesOrNo("Tem certeza que deseja excluir este orçamento ? ");
    setOpenDialogYesOrNo(true);
  }

  const fetchUpdatedColumns = async () => {
    try {
      const response = await apiClient.get('/tasks'); // Endpoint para buscar os dados atualizados
      setColumns(response.data); // Atualiza o estado local com os dados do backend
      setIsModalVisible(false);
    } catch (error) {
      console.error("Erro ao buscar dados atualizados do backend:", error);
    }
  };

  const handleSaveOk = () => {
    setIsModalVisible(false);
    fetchUpdatedColumns();
  }

  const onReloadIntern = () => {
    setIsModalVisible(false);
    fetchUpdatedColumns();
  }

  const handleDialogCloseYesOrNo = () => {
    setOpenDialogYesOrNo(false);
  };

  const handleDialogReloadCloseYesOrNo = () => {
    setOpenDialogReloadYesOrNo(false);
  };

  const deleteCommissionAndRecalc = () => {
      console.log("Deletando item commission");
      deleteTask(task, column);
      setOpenDialogYesOrNo(false)
  }

  const deleteCommissionReloadAndRecalc = () => {
      console.log("Reload após cadastrar");
      reloadTask(task, column);
      setOpenDialogReloadYesOrNo(false)
  }

  const handleChangeClient = async (event, newValue) => {

    try {
      let response;
  
      if (!newValue) {
        response = await apiClient.get('/tasks');
      } else {
        response = await apiClient.get(`/tasks/find/${newValue.idcustomer}`);
      }
  
      const updatedColumns = response.data.map(column => {
        const totalValue = column.tasks.reduce((sum, task) => sum + parseFloat(task.ticket || 0), 0);
        return { ...column, vltotalColumn: totalValue };
      });
  
      setColumns(updatedColumns);
      setIsModalVisible(false);
    } catch (error) {
      console.error("Erro ao buscar dados atualizados do backend:", error);
    }

  }

  const handleChangeSaller = async (event, newValue) => {

    try {
      let response;
      
      if (!newValue) {
        response = await apiClient.get('/tasks');
      } else {
        response = await apiClient.get(`/tasks/find/saller/${newValue.iduser}`);
      }
  
      const updatedColumns = response.data.map(column => {
        const totalValue = column.tasks.reduce((sum, task) => sum + parseFloat(task.ticket || 0), 0);
        return { ...column, vltotalColumn: totalValue };
      });
  
      setColumns(updatedColumns);
      setIsModalVisible(false);
    } catch (error) {
      console.error("Erro ao buscar dados atualizados do backend:", error);
    }

  }

  return (
    <DndProvider backend={HTML5Backend}>
      {isLoading ? (
        <SimpleBackdrop open={true} handleClose={handleClose} title={"Carregando informações..."} />
      ) : (
        <Content>

          <ResponsiveDialog
            open={openDialog}
            handleClose={handleDialogClose}
            title={titleDialog}
            message={messageDialog}
            status={statusDialog}
          />

          <DialogConfirm
            open={openDialogYesOrNo}
            handleClose={handleDialogCloseYesOrNo}
            handleOk={deleteCommissionAndRecalc}
            title={titleDialogYesOrNo}
            message={messageDialogYesOrNo}
            status={statusDialogYesOrNo}
          />

        <DialogConfirm
            open={openDialogReloadYesOrNo}
            handleClose={handleDialogReloadCloseYesOrNo}
            handleOk={deleteCommissionReloadAndRecalc}
            title={titleDialogReloadYesOrNo}
            message={messageDialogReloadYesOrNo}
            status={statusDialogReloadYesOrNo}
          />

          <Paper
              sx={{
                  width: '95vw', // Largura responsiva padrão
                  maxWidth: 1450, // Limite máximo de largura para telas médias
                  height: 'calc(100vh - 70px)', // Altura responsiva ajustada ao tamanho da tela menos a altura do AppBar
                  margin: '0 auto', // Centraliza horizontalmente
                  overflow: 'hidden', // Esconde conteúdo excedente
                  display: 'flex', // Define layout flexível
                  flexDirection: 'column', // Organiza o conteúdo verticalmente
                  boxShadow: 3, // Sombra para o efeito de elevação
                  overflowY: 'auto', // Permite rolagem vertical
                  '@media (max-width: 600px)': { // Ajuste para telas pequenas
                  width: '100%', // Largura total em telas menores
                  padding: '10px',
                  maxWidth: 1000,
                  overflow: 'hidden',
                  },
                  '@media (min-width: 600px) and (max-width: 1680px)': { // Ajuste para telas médias até 1680px
                  width: '100%',
                  maxWidth: 1100, // Define um limite para largura específica nessa resolução
                  height: '80vh',
                  overflow: 'hidden',
                  },
                  '@media (min-width: 900px) and (max-width: 1440px)': {
                      width: '100%', // Garante 100% da largura da tela para essa resolução
                      maxWidth: 1280, // Remove espaçamento interno
                  },
                  '@media (min-width: 1680px) and (max-width: 1920px)': { // Ajuste para telas médias maiores
                  width: '100%', // Ajusta a largura para criar margens equilibradas
                  maxWidth: 1500, // Limita a largura máxima
                  overflow: 'hidden',
                  },
                  '@media (min-width: 1920px)': { // Ajuste para telas grandes (monitores de 27 polegadas ou mais)
                  width: '100%', // Reduz a largura para criar margens maiores em telas muito largas
                  maxWidth: 1750, // Limita a largura máxima para evitar esticar muito
                  overflow: 'hidden',
                  },
              }}
            >

            <AppBar
                position="relative" // Posição relativa
                color="default"
                elevation={0}
                sx={{
                    width: '100%',
                    height: '70px', // Altura fixa para o AppBar
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                }}
            >
               <Toolbar>

                  <Grid container spacing={2} alignItems="center">
                      <Grid item style={{ width: 200 }}>
                          <Autocomplete 
                              size='small'
                              id="combo-box-demo"
                              // value={idCustomer && idCustomer}
                              sx={{ width: '100%' }}
                              getOptionLabel={(listCustomer) => `${listCustomer.name ? listCustomer.name : "Escolha o cliente"}`}
                              options={listCustomer}
                              isOptionEqualToValue={(option, value) => option.name === value.name}
                              onChange={handleChangeClient}
                              noOptionsText={"Nenhum Cliente com esse nome foi encontrando"}
                              renderInput={(params) => (
                                  <TextField
                                  {...params}
                                  label="Escolher cliente"
                                  />
                              )}
                          />

                          
                      </Grid>
                      <Grid item style={{ width: 200 }}>
                            <Autocomplete
                                size='small'
                                id="combo-box-demo"
                                sx={{ width: '100%' }}
                                getOptionLabel={(listUser) => `${listUser.name ? listUser.name : "Escolha o vendedor"}`}
                                options={listSaller}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                onChange={handleChangeSaller}
                                noOptionsText={"Nenhum vendedor com esse nome foi encontrando"}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Escolher vendedor"
                                    />
                                )}
                            />
                      </Grid>
                      <Grid item xs />
                      
                      <Grid item>
                        <Button
                                onClick={openHandleOk}
                                style={{ background: 'black', color: '#FFF' }}
                                sx={{ mr: 1 }}
                            >
                                <AddIcon /> Novo
                            </Button>
                      </Grid>
                  </Grid>
                
                </Toolbar>
            </AppBar>
              
            
              
            <Row span={24} style={{ overflowX: 'auto', display: 'flex', flexWrap: 'nowrap' }}>
                {columns.map((column) => (
                  <Column
                      key={column.idcolumn}
                      idcolumn={column.idcolumn}
                      name={column.name}
                      tasks={column.tasks}
                      moveTask={moveTask}
                      vltotalColumn={column.vltotalColumn}
                      onDelete={deleteTaskIntern}
                      onCloseSalve={onReloadIntern}
                    />
                  ))}
            </Row>
            
          </Paper>

          <Modal
            title={"Criar um novo orçamento"}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null} // Remove o rodapé
            centered // Centraliza o modal na tela
            style={{ top: '5vh' }} // Centraliza verticalmente com base na viewport
            bodyStyle={{
              height: '80vh', // Define a altura como 90% da altura da viewport
              padding: 0,
              margin: 0,
              overflow: 'auto', // Permite rolagem se o conteúdo ultrapassar o modal
            }}
            width="90vw" // Define a largura como 90% da largura da viewport
            closable={true} // Remove o botão de fechar
            maskStyle={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Altera a opacidade do fundo
            }}
          >
            <div>
              <NewAvailability onCloseSave={handleSaveOk} onClose={handleSaveOk}/>
            </div>
          </Modal>
        </Content>
      )}
    </DndProvider>
  );
};

export default MainKamban;
