import { apiClient } from "../api";

const budgetService = {


    async findBudgetById(idbudget){
        var retorno = await apiClient.get('/rel/budget/'+idbudget);

        return retorno.data;
    },


}

export default budgetService;