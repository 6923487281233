import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logoImage from '../../assets/img/logo-nova.jpeg';
import { Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import budgetService from "../../service/rels/relBudget";
import moment from "moment";


const PDFGenerator = ({idbudget}) => {

    const config = true ? {style: 'currency', currency: 'BRL'} : {minimumFractionDigits: 2};

    const openPDFInNewTab = (idbudget) => {

    const doc = new jsPDF();
    const imgWidth = 100; // Largura da imagem
    const imgHeight = 30; // Altura da imagem
    doc.addImage(logoImage, "PNG", 10, 10, imgWidth, imgHeight);


    moment.locale('pt-br');

    budgetService.findBudgetById(idbudget).then(item => {
        
        console.log(item)
        // Adicionando o texto no header
        doc.setFontSize(10);
        doc.text("TREND MIDIA LTDA", 120, 10);
        doc.text("CNPJ: 26.294.219/0001-34", 120, 15);
        doc.text("Avenida República do Líbano,", 120, 20);
        doc.text("251 – Torre C Sala 1504", 120, 25); 
        doc.text("Pina, Recife - PE", 120, 30);
        doc.text("CEP: 51.110-160", 120, 35);
        doc.text("Exibidora: Trend Mídia", 120, 40);
    
        // Adicionando tabela dividida em duas colunas
        doc.setFontSize(12);
        doc.autoTable({
          startY: 65,
          margin: { left: 10 },
          styles: { fontSize: 10 },
          head: [["CLIENTE: " + item.customer, "AUTORIZAÇÃO Nº: " + item.autorizedNumber]],
          body: [
            ["PRODUTO: " + item.product , "ATENDIMENTO: " + item.saller],
            ["PERÍODO: " + item.dias + " DIAS", ""],
            ["INÍCIO: " + moment(item.dtstart).format('DD/MM/YYYY'), "TÉRMINO: " + moment(item.dtend).format('DD/MM/YYYY')],
            ["PRAÇA:", ""],
            ["QUANTIDADE DE PONTOS: " + item.qtdPaineis + (item.qtdPaineis == 1 ? " painel " : " painéis "), ""],
          ],
        });
      
    
        // Adicionando a segunda tabela
        doc.setFontSize(12);
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 15,
          margin: { left: 10 },
          head: [["Descrição"]],
          body: [["Exibição durante " + item.dias + " dias, em " + item.qtdPaineis 
            + (item.qtdPaineis == 1 ? " painel " : " painéis ") +  "\n (Degustação), Painéis: " 
            + item.paineis.map(painel => painel.name)
          ]],
          headStyles: { halign: "center" }, 
          foot: [["VALOR LÍQUIDO: " + parseFloat(item.ticketValue).toLocaleString('pt-BR', config)]],
        });
    
        doc.setFontSize(10);
        const faturamentoY = doc.lastAutoTable.finalY + 10;
    
        // Linha normal
        doc.setFont("helvetica", "bold");
        doc.text("Razão Social: ", 10, faturamentoY);
    
        doc.setFont("helvetica", "normal");
        doc.text(item.customer, 40, faturamentoY);
    
        // Resetando para estilo normal
        doc.setFont("helvetica", "bold");
        doc.text("CNPJ: ", 10, faturamentoY + 5);
        doc.setFont("helvetica", "normal");
        doc.text(item.cpfcnpj, 40, faturamentoY + 5);
    
        // Continuar com estilo normal
        doc.setFont("helvetica", "bold");
        doc.text("Endereço: ", 10, faturamentoY + 10);
        doc.setFont("helvetica", "normal");
        doc.text(item.address + ", " + item.number + ", " + item.neighborhood + " ", 40, faturamentoY + 10);
    
        // Repita para outros trechos onde quiser aplicar negrito.
        doc.setFont("helvetica", "bold");
        doc.text("CEP: ", 10, faturamentoY + 15);
        doc.setFont("helvetica", "normal");
        doc.text(item.cep, 40, faturamentoY + 15);
    
        doc.setFont("helvetica", "bold");
        doc.text("DADOS PARA FATURAMENTO:", 10, faturamentoY + 30);
    
    
        doc.setFont("helvetica", "bold");
        doc.text("VALOR: ", 10, faturamentoY + 40);
        doc.setFont("helvetica", "normal");
        doc.text(parseFloat(item.ticketValue).toLocaleString('pt-BR', config), 40, faturamentoY + 40);
    
        doc.setFont("helvetica", "bold");
        doc.text("VENCIMENTO: ", 10, faturamentoY + 45);
        doc.setFont("helvetica", "normal");
        doc.text("", 40, faturamentoY + 45);
    
        doc.setFont("helvetica", "bold");
        doc.text("FORMA DE PAGAMENTO: ", 10, faturamentoY + 50);
        doc.setFont("helvetica", "normal");
        doc.text("", 60, faturamentoY + 50);
    
        doc.setFont("helvetica", "bold");
        doc.text("E-MAIL PARA COBRANÇA: ", 10, faturamentoY + 55);
        doc.setFont("helvetica", "normal");
        doc.text("", 60, faturamentoY + 55);
    
    
    
        // Gerar a data atual formatada
        const currentDate = new Date();
        const formattedDate = new Intl.DateTimeFormat("pt-BR", {
          day: "numeric",
          month: "long",
          year: "numeric",
        }).format(currentDate);
    
        // Adicionar a data ao PDF
        doc.text(`Recife, ${formattedDate}`, 10, faturamentoY + 80);
    
        // Adicionando assinatura
        const signatureY = faturamentoY + 100;
        doc.line(10, signatureY, 80, signatureY); // Linha para assinatura do cliente
        doc.text("Assinatura do cliente", 10, signatureY + 5);
    
        doc.line(120, signatureY, 190, signatureY); // Linha para assinatura da TrendMídia
        doc.text("Trend Mídia", 120, signatureY + 5);
    
        // Abrir em uma nova aba
        const pdfBlob = doc.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");

    }).catch(error => {
        console.log(error)
    });


  };

  return (
    <div style={{textAlign: "center" }}>
      
            <Button
                onClick={e=> {openPDFInNewTab(idbudget)}}
                style={{ background: 'black', color: '#FFF' }}
                // sx={{ mr: 1 }}
            >
                <PrinterOutlined /> Imprimir
            </Button>
    </div>
  );
};

export default PDFGenerator;